export const en: { [key: string]: string } = {
  administratorAccount: 'Administrator account',
  areYouSure: 'Are you sure?',
  create: 'Create',
  delete: 'Delete',
  editProject: 'Edit Project',
  editTerm: 'Edit Term',
  editUser: 'Edit User',
  'error.loginFailed': 'Login failed. Your username and password combination is incorrect.',
  'error.passwordRequired': 'Password is required',
  'error.usernameRequired': 'Username is required',
  fields: 'Field',
  glossary: 'Glossary',
  history: 'History',
  logOut: 'Log out',
  name: 'Name',
  password: 'Password',
  projects: 'Projects',
  projectCreated: 'Project has been created.',
  projectDeleted: 'Project has been deleted.',
  projectUpdated: 'Project has been updated.',
  search: 'Search',
  settings: 'Settings',
  settingsUpdated: 'Settings have been updated',
  signIn: 'Sign in',
  newGlossary: 'New Glossary',
  newTerm: 'New Term',
  termCreated: 'Term has been created.',
  termDeleted: 'Term has been deleted.',
  termUpdated: 'Term has been updated.',
  update: 'Update',
  username: 'Username',
  users: 'Users'
};
