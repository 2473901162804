import { GridSelectionModel } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { Content, CreateButton, Grid, Layout, Sidebar } from 'components';
import { TermForm } from 'components/terms/TermForm';
import { getColumns } from 'helpers/grid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Project as ProjectModel } from 'types';
import { UserContext } from 'UserContext';

export const Project: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<ProjectModel | undefined>();
  const [term, setTerm] = useState<any>();
  const [terms, setTerms] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [termFormOpen, setTermFormOpen] = useState(false);

  const fields = [project?.field1, project?.field2, project?.field3, project?.field4].filter(
    field => field && field !== ''
  ) as string[];

  const loadTerms = useCallback((projectId: any): void => {
    setLoading(true);
    axios
      .get(`/api/terms/${projectId}`)
      .then(response => {
        setLoading(false);
        const { project, terms } = response.data;
        const {
          id,
          name,
          field1,
          field2,
          field3,
          field4,
          createdAt,
          updatedAt,
          lastUpdated,
          total
        } = project as ProjectModel;

        setProject({
          id,
          name,
          field1,
          field2,
          field3,
          field4,
          createdAt,
          updatedAt,
          lastUpdated,
          total
        });

        setTerms(
          terms.map(
            ([
              id,
              projectId,
              field1,
              field2,
              field3,
              field4,
              createdAt,
              createdBy,
              updatedAt,
              updatedBy,
              status
            ]: any) => {
              return {
                id,
                projectId,
                field1,
                field2,
                field3,
                field4,
                createdAt,
                createdBy,
                updatedAt,
                updatedBy,
                status
              };
            }
          )
        );
      })
      .catch(() => {
        setRedirect('/logout');
      });
  }, []);

  const createTerm = (): void => {
    setTermFormOpen(true);
    setTerm(undefined);
  };

  const editTerm = (currentTermId: any): void => {
    const currentTerm = terms.find(({ id }) => id === currentTermId);
    if (user && currentTerm) {
      setTermFormOpen(true);
      setTerm(currentTerm);
    }
  };

  useEffect(() => {
    if (!user.isAdmin && !user.projects.some((project: ProjectModel) => project.id === Number(id))) {
      setRedirect('/');
    }

    loadTerms(id);
  }, [id, loadTerms, user.isAdmin, user.projects]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Layout>
      <Content>
        <s.div>
          <s.h1>{project?.name}</s.h1>
          {user.canEdit && <CreateButton onClick={createTerm} text={'New Term'} />}
        </s.div>
        <Grid
          name={project?.name ?? ''}
          columns={getColumns(project)}
          rows={terms}
          loading={loading}
          searchFields={fields.map((_: any, index: number) => `field${index + 1}`) || []}
          onRowSelected={(selectionModel: GridSelectionModel): void => {
            editTerm(selectionModel[0]);
          }}
        />
      </Content>
      <Sidebar visible={termFormOpen}>
        <TermForm project={project} term={term} setVisible={setTermFormOpen} onRefresh={(): void => loadTerms(id)} />
      </Sidebar>
    </Layout>
  );
};

const s = {
  h1: styled.h1`
    font-size: 14px;
    font-weight: normal;
    line-height: 30px;
    margin: 0px;
    padding: 5px 10px 5px 0px;
    text-transform: uppercase;
  `,
  div: styled.div`
    display: flex;
    height: 40px;
    width: 100%;
  `
};
